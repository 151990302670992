<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="false"
                :title="pageTitle"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >

                <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="!isRoutePharmacyBillReturn">
                    <label for="colFormLabel" class="col-form-label">Bill type: </label>
                    <v-select
                        placeholder="Select Bill Type"
                        v-model="billType"
                        :options="billTypeList"
                        label="label"
                        :reduce="label => label.value"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Patient: </label>
                    <AsyncSelect
                        placeholder="Patient Name, ID, Mobile No"
                        v-model="patient"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalQuery"
                        :format-label="formatPatientLabel"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="bill-number" class="col-form-label">Bill Number: </label>
                    <input
                      id="bill-number"
                      class="form-control"
                      v-model="billNumber"
                      @keyup="onClickGo(true)"
                      placeholder="Search by bill number"
                    />
                </div>
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2">
            <BillManagerListTable :salesList="salesList" :billTypeList="billTypeList" :billType="billType"/>
        </div>
        <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {computed, inject, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import handleContact from "@/services/modules/contact";
import BillManagerListTable from "@/components/molecule/company/hospital/billing/bill-manager/ListTable.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleInventory from "@/services/modules/inventory";

const router = useRouter()
const store = useStore()
let offset = ref(10)
let salesList = ref([])
const billTypeList = ref([])
const patient = ref(null)
const billType = ref(null)
const billNumber = ref('')
const route = useRoute()
const showError =  inject('showError');

const {fetchSalesList} = handlePurchase()
const {fetchContactProfiles} = handleContact();
const {formatPatientLabel} = useAsyncDropdownHelper()
const {fetchHome} = handleInventory()

const additionalQuery = {
    type: "patient"
}

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})

const isRoutePharmacyBillReturn = computed(() => {
    return route.name === 'pharmacy-bill-return-list';
})

const pageTitle = computed(() => {
    return route.name === 'pharmacy-bill-return-list' ? 'Pharmacy Bill Return' : 'Bill Manager List';
})

function getQuery (billNumberSearch = false) {
    //update url
    let routeQuery = Object.assign({}, route.query)
    routeQuery.sale_type = billType.value
    router.push({path: route.path, query: routeQuery})

    let query = '?company_id=' + companyId.value
    query += `&sale_type=${billType.value}`
    if(!!patient.value) query += '&contact_profile_id=' + patient.value.id
    if(!!billNumber.value) query += '&bill_number=' + billNumber.value
    if(page.value) query += '&page='+page.value
    query += '&offset='+offset.value
    if (billNumberSearch) return query
    if(!startDate.value && !endDate.value) return query
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    return query
}

function onClickGo(billNumberSearch = false) {

    fetchSalesList(getQuery(billNumberSearch))
        .then(res => {
            if(res.status) {
                salesList.value = res.date.data
                setPagination(res.date)
            }else {
                salesList.value = []
                store.commit("resetPagination")
            }

        })
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange (page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    onClickGo()
}

onMounted(() => {
    fetchHome().then(res => {
        if (res.data) {
            for (const value of Object.keys(res.data.hospital_invoice_types)) {
                billTypeList.value.push({
                    value: value,
                    label: res.data.hospital_invoice_types[value]
                })
            }
        }

        if (isRoutePharmacyBillReturn.value) {
            billType.value = 'pharmacy_invoice';
            onClickGo();
            return;
        }

        if (route.query.sale_type) {
          billType.value = route.query.sale_type;
          onClickGo();
        }
    })
})

</script>
